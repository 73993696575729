import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { Modal, UserTypeDetails } from '../../components';
import { currentUserSelector, fetchCurrentUser } from '../../ducks/user.duck';
import { hasUserType } from '../../util/genericHelpers';
import {
  updateProfile,
  updateProfileInProgress,
} from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { useConfiguration } from '../../context/configurationContext';
import css from './LandingPage.module.css';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { useIntl } from 'react-intl';
import { authenticationInProgress, selectIsAuthenticated } from '../../ducks/auth.duck';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, onManageDisableScrolling, inProgress, error } = props;

  const dispatch = useDispatch();
  const config = useConfiguration();
  const intl = useIntl();

  const [showMissingInformationReminder, setShowMissingInformationReminder] = useState(false);

  const currentUser = useSelector(currentUserSelector);
  const updateInProgress = useSelector(updateProfileInProgress);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const authInProgress = useSelector(authenticationInProgress);

  const isUserTypeMissing = !hasUserType(currentUser);

  const { userFields, userTypes = [] } = config.user;

  useEffect(() => {
    if (isUserTypeMissing && isAuthenticated) {
      setShowMissingInformationReminder(isUserTypeMissing);
    }
  }, [currentUser, showMissingInformationReminder]);

  const handleUpdateProfile = values => {
    const updatedValues = Object.entries(values).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key.startsWith('pub_') ? key.slice(4) : key]: value,
      }),
      {}
    );

    const finalValues = {
      publicData: updatedValues,
    };

    dispatch(updateProfile(finalValues)).then(() => {
      dispatch(fetchCurrentUser());
      setShowMissingInformationReminder(false);
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
    });
  };

  return (
    <>
      <PageBuilder
        pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
        inProgress={inProgress}
        error={error}
        isLandingPage
        fallbackPage={<FallbackPage error={error} />}
      />
      {isUserTypeMissing && isAuthenticated ? (
        <Modal
          id="MissingInformationReminder"
          containerClassName={css.containerClassName}
          isOpen={showMissingInformationReminder}
          onClose={() => {
            setShowMissingInformationReminder(!showMissingInformationReminder);
          }}
          usePortal
          hideCloseButton
          onManageDisableScrolling={onManageDisableScrolling}
          closeButtonMessage={intl.formatMessage({ id: 'ListingClaimModal.later' })}
        >
          <UserTypeDetails
            className={css.rootClassName}
            onSubmit={handleUpdateProfile}
            inProgress={updateInProgress}
            userFields={userFields}
            userTypes={userTypes}
          />
        </Modal>
      ) : null}
    </>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps, mapDispatchToProps))(LandingPageComponent);

export default LandingPage;
